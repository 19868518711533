<template>
  <div class="d-flex flex-column flex-root h-100">
    <!--begin::Login-->
    <div class="login login-5 login-signin-on d-flex flex-row-fluid" id="kt_login">
      <div class="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
           style="background-image: url(/assets/media/bg/bg-2.jpg);">
        <div class="login-form text-center text-white p-7 position-relative overflow-hidden">
          <!--begin::Login Header-->
          <div class="d-flex flex-center mb-15">
            <a href="#">
              <img src="/assets/media/logos/miracle-more-dark.png" class="max-h-75px" alt="" width="142" height="63"/>
            </a>
          </div>
          <!--end::Login Header-->
          <!--begin::Login Sign in form-->
          <div class="login-signin">
            <div class="mb-20">
              <h3 class="opacity-40 font-weight-normal">{{ $t('commons.reset_passwd_title') }}</h3>
              <p class="opacity-40"></p>
            </div>
            <form class="form" id="kt_login_signin_form" novalidate @keyup.enter="passwordResetBtn">
              <div class="form-group">
                <div class="col-12 p-0">
                  <input class="form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8"
                         type="email" v-model="passwordReset.email" :placeholder="$t('commons.email')" />
                </div>
              </div>
              <div class="form-group">
                <div class="col-12 p-0">
                  <i class="showPassword " @click="switchPasswordType()"
                     v-bind:class="passwordType === 'password' ? 'passwordEyeOff' : 'passwordEye'"></i>
                  <input class="form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8"
                         :type="passwordType" v-model="passwordReset.password" :placeholder="$t('commons.password')" />
                </div>
              </div>
              <div class="form-group">
                <div class="col-12 p-0">
                  <i class="showPassword " @click="switchPasswordType()"
                     v-bind:class="passwordType === 'password' ? 'passwordEyeOff' : 'passwordEye'"></i>
                  <input class="form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8"
                         :type="passwordType" v-model="passwordReset.password_confirmation" :placeholder="$t('commons.password_confirm') "/>
                </div>
              </div>
              <div class="form-group">
                <button @click="turnLogin" type="button" id="kt_login_forgot_cancel"
                        class="btn btn-pill btn-outline-white opacity-70 px-15 py-3 m-2">
                  {{ $t('commons.cancel') }}
                </button>
                <button id="kt_login_forgot_submit"
                        type="button"
                        class="btn btn-pill btn-primary opacity-90 px-15 py-3 m-2"
                        @click="passwordResetBtn"
                >
                  {{ $t('commons.submit') }}
                </button>
              </div>
            </form>
          </div>
          <Language></Language>
          <!--end::Login Sign in form-->
        </div>
      </div>
    </div>
    <!--end::Login-->
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import Language from '@/components/layout/Language';
import Vue from "vue";

export default {
  name: 'Login',
  data() {
    return {
      passwordType: 'password'
    }
  },
  methods: {
    ...mapActions('customer', ['POST_PASSWORD_RESET']),
    passwordResetBtn() {
      const email = this.passwordReset.email;
      const password = this.passwordReset.password;
      const password_confirmation = this.passwordReset.password_confirmation;
      if (!this.validEmail(email)) {
        Vue.$toast.error(this.$t('custom_messages.invalid_field_format', {field: this.$t('commons.e_mail')}));
        return;
      }
      if (password === '') {
        Vue.$toast.error(this.$t('custom_messages.invalid_field_format', {field: this.$t('commons.password')}));
        return;
      }
      if (password_confirmation === '') {
        Vue.$toast.error(this.$t('custom_messages.invalid_field_format', {field: this.$t('commons.password')}));
        return;
      }

      this.POST_PASSWORD_RESET();
    },
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    },
    turnLogin() {
      this.$router.push("/login")
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  },
  components: {
    Language
  },
  computed: {
    ...mapState({
      passwordReset: state => state.customer.passwordReset,
      isLoading: state => state.customer.isLoading,
    }),
    token: {
      get() {
        return this.$store.state.customer.passwordReset.token;
      },
      set(value) {
        this.$store.commit('customer/updateToken', value);
      }
    },
  },
  created() {
    this.token = this.$route.params.token;
  }
}
</script>
<style lang="scss">
.login.login-5.login-forgot-on .login-forgot {
  display: block;
}

.login.login-5 .login-form {
  width: 100%;
  max-width: 450px;
}

@media (max-width: 575.98px) {
  .login.login-5 .login-form {
    width: 100%;
    max-width: 100%;
  }
}

.preloader {
  width: 140px;
  margin: 0 auto;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    margin-left: -15px;
  }
}
</style>
